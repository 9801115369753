/* eslint-disable no-undef */
import React from 'react'
import { Container, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { Button } from '../components/common'
import defaultTheme from '../assets/styles/themes'
import { LayoutContext } from '../components/Layout'

// import { InfoModal } from '../components/modals/InfoModal'

const HELP_QUERY = graphql`
  query HELP_QUERY {
    helpImg: file(name: { eq: "help-img" }, ext: { eq: ".png" }) {
      id
      publicURL
    }
  }
`

export default function Help({ location }) {
  const [sum, setSum] = React.useState('10')
  const { openModal } = React.useContext(LayoutContext)

  React.useEffect(() => {
    const queryString = location.search
    const urlParams = new URLSearchParams(queryString)
    const success = urlParams.get('success')
    if (success === '1') {
      openModal({ component: 'ThankYou', props: { help: true } })
      window.history.pushState(null, null, location.pathname)
    }
    if (success === '0') {
      openModal({
        component: 'Error',
        props: { msg: 'Try again later' },
      })
      window.history.pushState(null, null, location.pathname)
    }
  }, [])
  const sums = {
    5: {
      uri: 'https://secure.wayforpay.com/button/b39d9484e376a',
      value: '5',
    },
    10: {
      uri: 'https://secure.wayforpay.com/button/bb546f4ef4c03',
      value: '10',
    },
    25: {
      uri: 'https://secure.wayforpay.com/button/ba85d91440f53',
      value: '25',
    },
    50: {
      uri: 'https://secure.wayforpay.com/button/b7147b1cb6822',
      value: '50',
    },
  }
  const { helpImg } = useStaticQuery(HELP_QUERY)
  return (
    <SectionS>
      <Container className="text-center">
        <TitleS>🙏 Thank you for helping us!</TitleS>
        <HelpImgS src={helpImg.publicURL} alt="Thank you for helping us!" />

        <Col
          xs={12}
          sm={{ span: 12 }}
          lg={{ span: 6, offset: 3 }}
          className="px-
					md-5"
        >
          <HelpTextS>
            All the contributions are allocating for developing and finalizing
            the tidykit user interface for Cinema 4d software to the product
            that is compatible with Windows 10 versions and macOS
            Sierra/Catalina operating systems.
          </HelpTextS>
          <SubTitleS>Donating Details</SubTitleS>
          <BtnsWrapperS>
            <BtnSumS
              onClick={() => setSum(sums[5].value)}
              active={sum === sums[5].value}
            >
              ${sums[5].value}
            </BtnSumS>
            <BtnSumS
              onClick={() => setSum(sums[10].value)}
              active={sum === sums[10].value}
            >
              ${sums[10].value}
            </BtnSumS>
            <BtnSumS
              onClick={() => setSum(sums[25].value)}
              active={sum === sums[25].value}
            >
              ${sums[25].value}
            </BtnSumS>
            <BtnSumS
              onClick={() => setSum(sums[50].value)}
              active={sum === sums[50].value}
            >
              ${sums[50].value}
            </BtnSumS>
          </BtnsWrapperS>
          <Button
            type="submit"
            aria-label="Submit the form"
            className="w-100 mx-0"
            onClick={async () => {
              // window.location.href =
              // 'https://secure.wayforpay.com/button/b2775904b0fed'
              window.location.href = sums[sum].uri
            }}
          >
            Help Tidykit with ${sum}.00
          </Button>
        </Col>
      </Container>
    </SectionS>
  )
}

Help.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
}

const SectionS = styled.div`
  padding-bottom: 190px;
  padding-left: 20px;
  @media screen and (max-width: 768px) {
    padding-bottom: 90px;
  }
`
const HelpImgS = styled.img`
  width: 100%;
  max-width: 679px;
  margin-top: 46px;
  @media screen and (min-width: 1200px) {
    padding-left: 5px;
    margin-right: -11px;
  }
`
const TitleS = styled.h1`
  font-size: 52px;
  margin-top: 45px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  @media screen and (min-width: 1200px) {
    padding-left: 26px;
    letter-spacing: 0.4px;
  }
`
const SubTitleS = styled.h3`
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.12;
  letter-spacing: 0.24px;
  margin-top: 47px;

  @media screen and (min-width: 1200px) {
    padding-left: 22px;
  }
`

const BtnsWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const HelpTextS = styled.p`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.6px;
  text-align: center;
  color: rgb(255, 255, 255);
  margin-top: 25px;
`
const BtnSumS = styled.button`
  background: none;
  color: #fff;
  outline: 0;
  width: 102px;
  height: 55px;
  border: solid 1px rgba(255, 255, 255, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 64px;
  font-size: 18px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 61px;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    border-color: ${defaultTheme.colors.primary};
  }
  &:active {
    border-color: ${defaultTheme.colors.primaryActive};
  }
  ${({ active }) =>
    active
      ? `
		border-color: ${defaultTheme.colors.primary} !important;
		cursor: default !important;
	`
      : null}
`
